import React, { useContext, useState } from "react"
import Layout from "../../../components/layout"
import ThemeContext from "../../../context/ThemeContext"
import SEO from "../../../components/seo"
import { graphql, Link } from "gatsby"
import MainSearch from "../../../components/main-search/main-search"
import { Tab, Tabs, Typography } from "@material-ui/core"
import styles from "./index.module.scss"
import stylesTabs from "../../special-landing/styles.module.scss"
import detailStyles from "../../game-providers/item.module.scss"
import Img from "gatsby-image"
import t from "../../../services/GetTranslationValue"
import _ from '../../../services/NullIf'
import { getFilterTranslationsByName } from "../../../services/preset-filters/get-all-filters"
import Rating from "@material-ui/lab/Rating"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import CreditCardIcon from '@material-ui/icons/CreditCard';
import localize from "../../../components/localize"
import BlockContent from "../../../components/text-helpers/portable-text"
import TableOfContent from "../../../components/table-of-content/table-of-content"

const Details = ({ translations, locale, pageData }) => {

  const processTime = (min, max) => {
    if (min === 0 && max === 0) {
      return t.get(translations, 'bank-page-details-instant')
    }
    else if (min === null && max === null) {
      return t.get(translations, 'bank-page-details-not-supported')
    }
    else {
      return `${min}-${max} ${t.get(translations, 'bank-page-details-days')}`
    }
  }

  return (
    <div>
      <section className={`${detailStyles.detailsContainer}`}>
        <div className={`${detailStyles.info}`}>
          <div className={`${detailStyles.logoContainer}`}>
            <Img
              alt={pageData.name}
              fluid={pageData.largeIcon.asset.fluid}
              className={`${detailStyles.logo}`}
            >
              {" "}
              {pageData.name}{" "}
            </Img>
          </div>
          <div className={`${detailStyles.infoContainer}`}>
            <div className={`${detailStyles.nameContainer}`}>
              <p className={`${detailStyles.name}`}>{pageData.name}</p>
              <p className={`${detailStyles.rating}`}>
                <Rating
                  readOnly={true}
                  name="half-rating"
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  size="small"
                  value={pageData && pageData.mrGambleRating || 0}
                  precision={0.5}
                />
              </p>
            </div>
            <div className={`${detailStyles.rowContainer}`}>
              <p className={`${detailStyles.infoRow}`}>
                <label>{t.get(translations, 'website')}: </label><span><a
                  target={`_blank`} rel={`noopener noreferrer nofollow`}
                  href={pageData.website}>{pageData.website}</a></span>
              </p>
              <p className={`${detailStyles.infoRow}`}>
                <label>{t.get(translations, 'bank-page-details-founded-date')}: </label><span>{pageData.foundedDate}</span>
              </p>
              <p className={`${detailStyles.infoRow}`}>
                <label>{t.get(translations, 'bank-page-details-company')}: </label><span>{pageData.company}</span>
              </p>
              <p className={`${detailStyles.infoRow} ${styles.languages}`}>
                <label>{t.get(translations, 'languages')}: </label><span>{pageData.languages && pageData.languages.map(x => x.name + ", ")}</span>
              </p>
            </div>
          </div>
          <div className={`${styles.processTime}`}>
            <span className={`${styles.processTimeTitle}`}>
              <CreditCardIcon/>
              {t.get(translations, 'bank-page-details-deposit-withdrawal-process-time')}
            </span>
            <div className={`gamble-hidden-xs-down ${styles.processTimeParent}`}>
              <p className={`${styles.labelContainer}`}>
                <label>{t.get(translations, 'bank-page-details-deposit')}</label>
                <label>{t.get(translations, 'bank-page-details-withdrawal')}</label>
              </p>
              <p className={`${styles.spanContainer}`}>
                <span>{processTime(pageData.minimumDepositTime, pageData.maximumDepositTime)}</span>
                <span>{processTime(pageData.minimumWithdrawalTime, pageData.maximumWithdrawalTime)}</span>
              </p>
            </div>
            <div className={`gamble-hidden-xs-up ${styles.processTimeParent}`}>
              <p className={`${styles.depositContainer}`}>
                <label>{t.get(translations, 'bank-page-details-deposit')}: </label>
                <span>{processTime(pageData.minimumDepositTime, pageData.maximumDepositTime)}</span>
              </p>
              <p className={`${styles.withdrawalContainer}`}>
                <label>{t.get(translations, 'bank-page-details-withdrawal')}: </label>
                <span>{processTime(pageData.minimumWithdrawalTime, pageData.maximumWithdrawalTime)}</span>
              </p>
            </div>
          </div>
        </div>
        <div className={`${detailStyles.games}`}>
          <div className={`${detailStyles.gamesParent}`}>
            <div className={`${detailStyles.game} ${detailStyles.bankFeatures}`}>
              {pageData.instantProcessing ?
                <img alt={t.get(translations, 'bank-page-details-instant-processing')} src={`/../../../icons/game-provider/normal/instant.svg`} />
                :
                <img alt={t.get(translations, 'bank-page-details-instant-processing')} src={`/../../../icons/game-provider/disabled/instant.svg`} />
              }
              <span className={`${pageData.instantProcessing ? "" : detailStyles.notAvailable}`}>
                {t.get(translations, 'bank-page-details-instant-processing')}
              </span>
            </div>
            <div className={`${detailStyles.game} ${detailStyles.bankFeatures}`}>
              {pageData.worldwideService ?
                <img alt={t.get(translations, 'bank-page-details-world-wide-service')} src={`/../../../icons/game-provider/normal/world-wide.svg`} />
                :
                <img alt={t.get(translations, 'bank-page-details-world-wide-service')} src={`/../../../icons/game-provider/disabled/world-wide.svg`} />
              }
              <span className={`${pageData.worldwideService ? "" : detailStyles.notAvailable}`}>
                {t.get(translations, 'bank-page-details-world-wide-service')}
              </span>
            </div>
            <div className={`${detailStyles.game} ${detailStyles.bankFeatures}`}>
              {pageData.freeOfCharge ?
                <img alt={t.get(translations, 'bank-page-details-free-of-charge')} src={`/../../../icons/game-provider/normal/free.svg`} />
                :
                <img alt={t.get(translations, 'bank-page-details-free-of-charge')} src={`/../../../icons/game-provider/disabled/free.svg`} />
              }
              <span className={`${pageData.freeOfCharge ? "" : detailStyles.notAvailable}`}>
                {t.get(translations, 'bank-page-details-free-of-charge')}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const Content = ({ casinos, translations, pageData, method, locale, showWithdrawal }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  let activeCasinos = [];
  // deposit
  if (value === 0){
     activeCasinos = casinos
      .filter(x => x.depositMethods)
      .filter(x => x.depositMethods.some(y => y && y.name === method)
    )
  }
  // withdrawal
  else {
    activeCasinos = casinos
    .filter(x => x.withdrawalMethods)
    .filter(x => x.withdrawalMethods.some(y => y && y.name === method)
  )

  }


  /*let h1 = null;

  if (pageData._rawDepositText) {
    h1 = pageData._rawDepositText.filter((x) => x.style === 'h1');
  }

  let headerResult = null;
  if (pageData._rawDepositText) {
    headerResult = pageData._rawDepositText.filter((x) => x.style !== 'h1');
  }

  let withdrawalResultHeader = null;
  if (pageData._rawWithdrawalText) {
    withdrawalResultHeader = pageData._rawWithdrawalText.filter((x) => x.style === 'h1');
  }

  let withdrawalResult = null;
  if (pageData._rawWithdrawalText) {
    withdrawalResult = pageData._rawWithdrawalText.filter((x) => x.style !== 'h1');
  }

  let depositResult = null;
  if (pageData._rawDepositTextFooter) {
    depositResult = pageData._rawDepositTextFooter.filter((x) => x.style !== 'h1');
  }
*/

  const filters = getFilterTranslationsByName(translations,
    [ "headingfilter-newproviders",
      "headingfilter-providerfreespins",
      "headingfilter-newpageDatacasinos"
    ])

  let h1 = pageData.h1;
  let headerResult = pageData._rawHeaderText;
  let footerResult = pageData._rawFooterText;

  return (
    <div className={`${stylesTabs.bonusPageTabsContainer}`}>
      <div className={`gambleGeneralWrapper`}>
        {h1 && <h1>{h1}</h1>}
        {headerResult && <BlockContent blocks={headerResult}/>}

      </div>
      {showWithdrawal && <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`gamble-tab ${styles.tabs}`}
      >
        <Tab
          label={t.get(translations, 'deposit-methods')}
          {...TabsIndex(0)}
          className={`${styles.tab}`}
        />
        <Tab
          label={t.get(translations, 'withdrawal-methods')}
          {...TabsIndex(1)}
          className={`${styles.tab}`}
        />
      </Tabs> }

      <div
        className={`${stylesTabs.tabContent}`}
      >
        <div className={`${stylesTabs.tabBody}`}>
          <MainSearch bonuses={activeCasinos} locale={locale} />
        </div>
      </div>
      <div className={`gambleGeneralWrapper`}>
        {/* {withdrawalResultHeader && withdrawalResultHeader[0] && <h2>{withdrawalResultHeader[0].children[0].text}</h2>} */}

        {/*withdrawalResult && <BlockContent blocks={withdrawalResult}/>*/}

        {/*depositResult && <BlockContent blocks={depositResult}/>*/}
        {footerResult && <BlockContent blocks={footerResult}/>}
        {/* {pageData._rawWithdrawalText && <BlockContent blocks={pageData._rawWithdrawalText} />} */}

      </div>

    </div>
  )
}

const BankingMethods = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)
  const { locale, withdrawalMethod, alternateLocaleSlugs } = pageContext

  let pageData = data.sanityPaymentMethod

  if (!pageData) pageData = {}
  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location} bcDark>
      <SEO location={location} title={pageData.seoTitle}
           alternateSlug={alternateLocaleSlugs}
           bonuses={bonuses} description={pageData.seoMeta} />
      <Details
        translations={translations}
        locale={locale}
        pageData={pageData}
      />
      <div className={`${styles.tocParent}`}>
        {pageData._rawHeaderText && <TableOfContent pageContext={pageData._rawHeaderText} />}
        {pageData._rawFooterText && <TableOfContent pageContext={pageData._rawFooterText} />}
      </div>
      <Content
        translations={translations}
        locale={locale}
        casinos={bonuses}
        pageData={pageData}
        method={withdrawalMethod}
        showWithdrawal={_.isNotNullOrEmpty(pageData.maximumWithdrawalTime)}
      />

    </Layout>
  )
}

export default localize(BankingMethods)
export const query = graphql`
  query BankingMethodQuery($withdrawalMethod: String!) {
    sanitySilo(_id: {eq: "76ec0ccd-5a65-4f6b-9e82-857614680f23"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityPaymentMethod(
      name: { eq: $withdrawalMethod }
    ) {
      name
      seoTitle {
          ...LocaleString
      }
      seoMeta{
          ...LocaleString
      }
      seoTitleWithdrawal {
          ...LocaleString
      }
      seoMetaWithdrawal {
          ...LocaleString
      }
      maximumWithdrawalTime
      largeIcon {
          asset {
              fluid {
                  ...GatsbySanityImageFluid
              }
          }
      }
      company
      foundedDate
      website
      freeOfCharge
      instantProcessing
      mrGambleRating
      worldwideService
      maximumDepositTime
      maximumWithdrawalTime
      minimumDepositTime
      minimumWithdrawalTime
      languages {
        name {
            ...LocaleString
        }
      }
      h1{
        ...LocaleString
      }
      _rawHeaderText(resolveReferences: {maxDepth: 10})
      _rawFooterText(resolveReferences: {maxDepth: 10})
    }
  }
`
